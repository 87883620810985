<template>
    <div v-if="isLoading" class="absolute z-[1000] inset-0 flex items-center justify-center bg-gray-100 z-50">
        <div class="loader"></div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps({
    isLoading: {
        type: Boolean,
        required: true
    }
});

const { isLoading } = toRefs(props);

</script>

<style scoped>
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #4f46e5;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>