import ApiService from '../services/api.service';
import { User } from '../models/user';
import qs from 'qs';

class UserService {
  login(username: string, password: string) {
    const data = qs.stringify({ username, password });
    return ApiService.instance.post('/token', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  register(username: string, email: string, password: string) {
    return ApiService.instance.post('/register', { username, email, password }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getUserData() {
    return ApiService.instance.get<User>('/users/me', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export default new UserService();
