// misc.ts
import moment from 'moment-timezone';

export const formatDateTimezone = (date: Date, timezone: string) => {
    return moment.tz(date, timezone).format('YYYY-MM-DD');
};

// Function to get the initial letter of a name
export const getInitial = (name: string): string => {
    return name ? name.substring(0, 1).toUpperCase() : '';
}

// Function to format a date as a string
export const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
}

// Function to check if an email is valid
export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

// Function to capitalize the first letter of each word in a string
export const capitalizeWords = (str: string): string => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

// Function to generate a unique identifier
export const generateUUID = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
