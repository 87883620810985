import ApiService from '../services/api.service';
import { Workspace } from '../models/workspace';
import qs from 'qs';

interface WorkspaceResponse {
  workspaces: Workspace[];
}

class WorkspaceService {
  getWorkspaces() {
    return ApiService.instance.get<WorkspaceResponse>('/workspaces');
  }

  getMeetingById(workspaceId: string) {
    return ApiService.instance.get<Workspace>(`/workspace/${workspaceId}`);
  }

  createWorkspace(workspaceName: string) {
    const data = qs.stringify({ 'name': workspaceName });
    return ApiService.instance.post('/workspace/create', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}

export default new WorkspaceService();
