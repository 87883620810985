<template>
    <div v-if="currentWorkspace" class="relative">
        <button @click="toggleDropdown" class="flex items-center text-gray-700 hover:text-indigo-600">
            <div class="flex items-center justify-center h-8 w-8 bg-indigo-600 hover:bg-indigo-500 rounded-lg">
                <img v-if="currentWorkspace.image" :src="currentWorkspace.image" class="h-6 w-6 rounded" :alt="currentWorkspace.name" />
                <span v-else class="text-sm font-medium text-white">{{ getInitial(currentWorkspace.name) }}</span>
            </div>
            <span v-if="!isCollapsed" class="ml-4 font-bold">{{ currentWorkspace.name }}</span>
            <svg v-if="!isCollapsed" :class="{ 'rotate-180': isOpen }" class="ml-2 h-5 w-5 transform transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed } from 'vue';
import type { PropType } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { getInitial } from '@/utils/misc';
import { Workspace } from '@/models/workspace';

const store = useStore();

const props = defineProps({
    workspaces: {
        type: Array as PropType<Workspace[]>,
        required: true,
    },
    isCollapsed: {
        type: Boolean,
        required: true,
    },
    isOpen: {
        type: Boolean,
        required: true,
    },
    toggleDropdown: {
        type: Function,
        required: true,
    },
});

const route = useRoute();

const currentWorkspace = computed(() => {
    if(store.state.token && props.workspaces && props.workspaces.length > 0) {
        return store.state.currentWorkspace || props.workspaces[0];
    }
    else {
        return null;
    }
});
</script>

<style scoped>
/* Add any custom styles for the dropdown here */
</style>
