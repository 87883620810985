<template>
  <div class="w-full h-full">

    <LoadingOverlay :isLoading="isLoading && isLoadingDisplayedOnRoute"></LoadingOverlay>
    <div v-if="!isLoading || !isLoadingDisplayedOnRoute">


      <div v-if="isMenuWorkspaceOpen" id="menu-workspace-choice" data-modal="true"
        class="absolute z-[999] left-3 top-[54px] w-64 origin-top-right rounded-md bg-indigo-600 shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="py-1">
          <a v-for="workspace in workspaces" :key="workspace.name" @click="selectWorkspace(workspace.id)"
            class="block px-4 cursor-pointer py-2 text-sm text-white hover:bg-indigo-500 hover:text-white hover:font-bold">
            <span
              class="mr-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 text-xs font-medium text-gray-800">{{
                getInitial(workspace.name) }}</span>
            {{ workspace.name }}
          </a>
        </div>
      </div>

      <div v-if="isMenuSettingsOpen" id="menu-settings-choice" data-modal="true"
        class="absolute z-[999] left-3 bottom-[54px] w-64 origin-top-right rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="py-1">
          <span @click="setting.action()" v-for="setting in settings" :key="setting.name"
            class="block box-border px-4 py-2 cursor-pointer text-sm text-white hover:bg-gray-500 hover:text-white hover:font-bold">
            <component :is="setting.icon"
              :class="['mr-2 inline-flex h-6 w-6 items-center justify-center rounded-full text-xs font-medium text-white']"
              aria-hidden="true" />
            {{ setting.name }}
          </span>
        </div>
      </div>

      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full" enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
              leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                  enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div class="flex h-16 shrink-0 items-center">
                    <SidebarDropdown id="dropdown-menu-workspace" :workspaces="workspaces.value"
                      :isOpen="isMenuWorkspaceOpen" :isCollapsed="isCollapsed"
                      :isMenuWorkspaceOpen="isMenuWorkspaceOpen" :toggleDropdown="toggleDropdown" />
                  </div>
                  <nav class="flex flex-1 flex-col">

                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in navigation" :key="item.name">
                            <router-link :to="item.href"
                              :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                              <component :is="item.icon"
                                :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                aria-hidden="true" />
                              <span v-if="!isCollapsed">{{ item.name }}</span>
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div v-if="isDisplayedOnRoute" :class="[isCollapsed ? 'lg:w-20' : 'lg:w-72']"
        class="hidden overflow-x-hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col transition-width duration-300">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div
          class="flex grow overflow-x-hidden flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div class="flex h-10 shrink-0 items-center mt-3">
            <SidebarDropdown id="dropdown-menu-workspace" :workspaces="workspaces" :isOpen="isMenuWorkspaceOpen"
              :isCollapsed="isCollapsed" :isMenuWorkspaceOpen="isMenuWorkspaceOpen" :toggleDropdown="toggleDropdown" />
            <img v-if="!isCollapsed" @click="toggleCollapse"
              class="h-8 w-auto ml-auto hover:bg-gray-200 cursor-pointer rounded-lg p-1 -m-2"
              src="/icons/sidebar_collapse.svg" alt="Collapse / Expand" />
          </div>

          <div v-if="isCollapsed" class="flex justify-center">
            <img @click="toggleCollapse" class="h-8 w-auto hover:bg-gray-200 cursor-pointer rounded-lg p-1"
              src="/icons/sidebar_collapse.svg" alt="Collapse / Expand" />
          </div>
          <hr v-if="isCollapsed" class="text-gray-400" />
          <nav :class="[isCollapsed ? '' : 'mt-12']" class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li v-for="item in navigation" :key="item.name">
                    <router-link :to="item.href"
                      :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold', isCollapsed ? 'ml-1' : '']">
                      <component :is="item.icon"
                        :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                        aria-hidden="true" />
                      <span v-if="!isCollapsed">{{ item.name }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="-mx-6 mt-auto hover:cursor-pointer hover:bg-gray-800 ">
                <div id="div-menu-settings" @click="toggleSettings"
                  class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 hover:bg-gray-50">
                  <img class="h-8 w-8 rounded-full bg-gray-50 hover:border-2 hover:border-indigo-500 cursor-pointer" src="/assets_public/mock/avatar_0.jpg" alt="" />
                  <span class="sr-only">Your profile</span>
                  <span aria-hidden="true" v-if="!isCollapsed">{{ userName }}</span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div v-if="isDisplayedOnRoute"
        class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
        <a href="#">
          <span class="sr-only">Your profile</span>
          <img class="h-8 w-8 rounded-full bg-gray-50"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt="" />
        </a>
      </div>

      <main :class="[isDisplayedOnRoute ? isCollapsed ? 'lg:pl-20' : 'lg:pl-72' : '']"
        class="h-full transition-all duration-300">
        <div class="px-4 sm:px-6 lg:px-8 h-full">
          <!-- Your content -->
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute, RouteLocationNormalizedLoaded } from 'vue-router';
import { useStore } from 'vuex';
import Cookies from "js-cookie";


import { getInitial } from '@/utils/misc';
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  InformationCircleIcon,
  ArrowLeftStartOnRectangleIcon
} from '@heroicons/vue/24/outline';
import LoadingOverlay from './components/LoadingOverlay.vue';
import SidebarDropdown from './components/SidebarDropdown.vue';

// State and Props #######################################################################################################################
const navigation = ref([
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
  { name: 'Team', href: '/team', icon: UsersIcon, current: false },
]);

const sidebarOpen = ref(false);
const isCollapsed = ref(true);
const isMenuWorkspaceOpen = ref(false);
const isMenuSettingsOpen = ref(false);
const isDisplayedOnRoute = ref(true);
const isLoadingDisplayedOnRoute = ref(false);

const store = useStore();
const route = useRoute();
const router = useRouter();

const userName = computed(() => store.state.user?.username || '');
const workspaces = computed(() => store.state.workspaces || []);
const isLoading = computed(() => store.state.currentWorkspace === null);

interface Setting {
  id: number;
  name: string;
  action: Object;
  icon: Object;
}

const settings: Setting[] = [];

// Methods #######################################################################################################################
const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};

const toggleDropdown = () => {
  isMenuWorkspaceOpen.value = !isMenuWorkspaceOpen.value;
};

const toggleSettings = () => {
  isMenuSettingsOpen.value = !isMenuSettingsOpen.value;
};

const logout = () => {
  Cookies.remove("token");
  isMenuWorkspaceOpen.value = false;
  isMenuSettingsOpen.value = false;
  router.push({ name: 'Login' });
};

const selectWorkspace = (workspaceId: string) => {
  store.state.currentWorkspace = store.getters.getWorkspaceById(workspaceId);
  toggleDropdown();
};

const handleClickOutside = (event: Event) => {
  const workspaceMenuTrigger = document.getElementById('dropdown-menu-workspace');
  const settingsMenuTrigger = document.getElementById('div-menu-settings');

  const workspaceMenu = document.getElementById('menu-workspace-choice');
  const settingsMenu = document.getElementById('menu-settings-choice');

  if (isMenuWorkspaceOpen.value && workspaceMenu && workspaceMenuTrigger &&
      (!workspaceMenu.contains(event.target as Node) && !workspaceMenuTrigger.contains(event.target as Node))) {
    isMenuWorkspaceOpen.value = false;
  }

  if (isMenuSettingsOpen.value && settingsMenu && settingsMenuTrigger &&
      (!settingsMenu.contains(event.target as Node) && !settingsMenuTrigger.contains(event.target as Node))) {
    isMenuSettingsOpen.value = false;
  }
};

const updateNavigationCurrent = (route: RouteLocationNormalizedLoaded) => {
  navigation.value.forEach(item => {
    item.current = item.href === route.path;
  });
};

const checkIfCurrentRouteVisibility = (route: RouteLocationNormalizedLoaded) => {
  switch (route.name) {
    case 'Login':
    case 'Register':
      return false;
    default:
      return true;
  }
};

const checkIfCurrentRouteVisibilityForLoading = (route: RouteLocationNormalizedLoaded) => {
  switch (route.name) {
    case 'Login':
    case 'Register':
      return false;
    default:
      return true;
  }
};

const notImplemented = () => {
  console.warn("Not implemented.");
}

// Registers #######################################################################################################################
settings.push({ id: 1, name: 'Settings', action: notImplemented, icon: Cog6ToothIcon });
settings.push({ id: 3, name: 'Manage billing', action: notImplemented, icon: CreditCardIcon });
settings.push({ id: 4, name: 'Logout', action: logout, icon: ArrowLeftStartOnRectangleIcon });

// Watchers #######################################################################################################################
watch(route, () => {
  isDisplayedOnRoute.value = checkIfCurrentRouteVisibility(route);
  isLoadingDisplayedOnRoute.value = checkIfCurrentRouteVisibilityForLoading(route);
  updateNavigationCurrent(route);
}, { immediate: true });

watch(workspaces, (newWorkspaces, oldWorkspaces) => {
  console.log('Workspaces have changed:', { oldWorkspaces, newWorkspaces });
  // Add any additional logic here that needs to happen when workspaces change
}, { deep: true });

// Lifecycle Hooks #######################################################################################################################

if (store.state.token) {
  store.dispatch('fetchUserData');
  store.dispatch('fetchWorkspaces');
}
else {
  console.warn("Token is not defined");
}


onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped></style>