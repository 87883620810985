<template>
  <div class="video-thumbnail relative">
    <div v-if="!imageError && thumbnailLink" :style="{ backgroundImage: `url(${thumbnailLink})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}" alt="Video Thumbnail"
      class="cursor-pointer w-full h-[200px] bg-black" @error="imageError = true"></div>
    <div v-else class="placeholder w-full h-[200px] flex items-center justify-center bg-indigo-200 text-gray-700">
      Miniature not available
    </div>
    <div v-if="permissions === 'private'" class="lock-icon absolute top-2 right-2 bg-white rounded-lg p-1">
      <svg class="w-6 h-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 20 20">
        <path fill="currentColor" fill-rule="evenodd"
          d="M5 9V7a5 5 0 0 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5a2 2 0 1 1 2-2m8-2v2H7V7a3 3 0 0 1 6 0"
          clip-rule="evenodd" />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { defineProps } from 'vue';
import MeetingService from '@/services/meeting.service';

const props = defineProps({
  meetingId: {
    type: String,
    required: true
  },
  meetingUrl: {
    type: String,
    required: true
  },
  permissions: {
    type: String,
    required: true
  }
});

const imageError = ref(false);
const thumbnailLink = ref('');

// Cache for thumbnails
const thumbnailCache = new Map();

onMounted(async () => {
  // Check if the thumbnail is already in the cache
  if (thumbnailCache.has(props.meetingId)) {
    thumbnailLink.value = thumbnailCache.get(props.meetingId);
    return;
  }

  try {
    const thumbnailData = await MeetingService.getThumbnail(props.meetingId);
    thumbnailLink.value = thumbnailData.thumbnail.link;

    // Store the fetched thumbnail in the cache
    thumbnailCache.set(props.meetingId, thumbnailLink.value);
  } catch (error) {
    console.error("The thumbnail could not be retrieved.", error);
    imageError.value = true;
  }
});
</script>

<style scoped>
.placeholder {
  /* equivalent to h-54 */
}
</style>
