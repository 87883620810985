import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'https://api-recorder.justai.co/',
      headers: {
        'Access-Control-Allow-Origin': '*', // Add any custom header here
      },
    });

    this.axiosInstance.interceptors.request.use(
      config => {
        console.log("Interceptor");
        const token = Cookies.get('token');
        console.log(token);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  get instance(): AxiosInstance {
    return this.axiosInstance;
  }
}

export default new ApiService();
