<template>
  <div class="video-detail-page h-screen flex flex-col items-center py-4 overflow-hidden">
    <div class="w-full flex justify-between items-center mb-4">
      <div class="flex items-center">
        <router-link to="/meetings"
          class="flex items-center hover:bg-gray-200 hover:rounded-md hover:text-indigo-600 hover:pl-2 -ml-2 -my-2 py-2 pl-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
          <span class="mx-2">Meetings</span>
        </router-link>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-3 h-3">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
        <div class="bg-gray-100 shadow p-1 rounded-md ml-1"><img src="/icons/google_meet.svg" class="w-4 h-4" /></div>
        <h1 class="text-xl font-bold ml-2">{{ meetingName }}</h1>
      </div>
      <div v-if="videoPermissions === 'private'"
        class="lock-icon bg-white rounded-lg p-1 relative top-1 cursor-pointer">
        <svg class="w-6 h-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
          viewBox="0 0 20 20">
          <path fill="currentColor" fill-rule="evenodd"
            d="M5 9V7a5 5 0 0 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m8-2v2H7V7a3 3 0 0 1 6 0"
            clip-rule="evenodd" />
        </svg>
      </div>
    </div>

    <div class="w-full flex flex-grow overflow-hidden">
      <div id="left-container" class="resize-x w-1/2">
        <form class="w-full relative flex-grow bg-white p-2 rounded-md shadow" @submit.prevent>
          <label for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="search" id="default-search" v-model="searchQuery" placeholder="Search (Ctrl+K)"
              class="block w-full p-4 pr-[50px] pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
              @keydown.ctrl.k.prevent="focusSearch" required />
            <button type="button"
              class="text-gray absolute right-2.5 bottom-1.5  hover:bg-gray-200 focus:outline-none font-medium rounded-lg text-sm px-2 py-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
              </svg>
            </button>
          </div>
        </form>

        <!-- <div class="cursor-pointer overflow-hidden border-r border-gray-200 p-4 mt-2 bg-white shadow rounded-lg">
          <h2 @click="toggleMeetingDetails" class="text-2xl font-semibold flex justify-between items-center">
            Meeting details
            <svg :class="{ 'rotate-180': isMeetingDetailsOpen }" class="ml-2 h-5 w-5 transform transition-transform"
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </h2>
          <div v-show="isMeetingDetailsOpen" class="mt-2 p-2">
            <p><span
                class="inline-block bg-gray-200 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-3">Date: {{
                  meetingDate }}</span></p>
            <p><span
                class="inline-block bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">Speakers</span>
            </p>
            <ul class="custom-list mt-1">
              <li v-for="speaker in speakers" :key="speaker" class="relative pl-4">
                <div class="absolute inset-y-0 left-0 w-0.5 bg-gray-300 ml-3"></div>
                <span class="ml-2">{{ speaker }}</span>
              </li>
            </ul>
          </div>
          <details v-show="isMeetingDetailsOpen" @click="toggleAttachmentDetails" class="mt-4 mb-2 border-2 rounded-md">
            <summary
              class="text-black relative cursor-pointer hover:text-indigo-600 hover:bg-gray-100 p-2 flex items-center">
              Attach documents
              <svg :class="{ 'rotate-180': isAttachmentOpen }"
                class="absolute right-3 h-5 w-5 transform transition-transform" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </summary>
            <div class="mt-2 p-2">
              <p>...</p>
            </div>
          </details>
        </div> -->

        <div class="cursor-pointer overflow-hidden border-r border-gray-200 p-4 mt-2 bg-white shadow rounded-lg">
          <h2 @click="toggleSummary" class="text-2xl font-semibold flex justify-between items-center">
            {{ $t('meeting-report') }}
            <svg :class="{ 'rotate-180': isSummaryOpen }" class="ml-2 h-5 w-5 transform transition-transform"
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </h2>
          <div v-show="isSummaryOpen" v-if="videoSummary" id="summary-container" class="flex flex-grow flex-row">
            <div class="flex-none w-[200px] overflow-y-auto mt-6">

              <div v-for="(entry, index) in Object.keys(videoSummary)" :key="index" class="mb-3">
                <span @click="selectedCategory = entry"
                  :class="(selectedCategory === entry) ? 'bg-violet-200 text-indigo-700 font-semibold' : 'bg-gray-200 hover:bg-violet-200 hover:text-violet-500'"
                  class="cursor-pointer w-[94%] inline-block text-sm font-medium mr-2 px-2.5 py-1 rounded">{{
                    processKeyToCapitalize(entry) }}</span>
              </div>

            </div>
            <div class="flex-auto overflow-y-auto mt-6 pr-6 bg-gray-50 rounded-lg border-2 border-gray-100 ml-3">
              <div class="mb-6 pl-6 pt-6" v-html="renderVideoSummary(videoSummary[selectedCategory])">
              </div>
            </div>
          </div>
        </div>

        <div class="cursor-pointer overflow-hidden border-r border-gray-200 p-4 mt-2 bg-white shadow rounded-lg">
          <h2 @click="toggleEmailDraft" class="text-2xl font-semibold flex justify-between items-center">
            {{ $t('meeting-draft-email') }}
            <svg :class="{ 'rotate-180': isEmailDraftOpen }" class="ml-2 h-5 w-5 transform transition-transform"
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </h2>
          <div v-show="isEmailDraftOpen" v-if="videoSummary" id="summary-container" class="flex flex-grow flex-row">
            <div class="flex-auto overflow-y-auto mt-6 pr-6 bg-gray-50 rounded-lg border-2 border-gray-100 ml-3">
              <div class="mb-6 pl-6 pt-6" v-html="renderVideoSummary(videoSummary[selectedCategory])">
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="flex flex-col items-start w-1/2 bg-white p-4 ml-4 rounded-md shadow"
        :class="[((!videoVisible && !transcriptVisible && !commentsVisible) || (videoVisible && !transcriptVisible && !commentsVisible)) ? 'h-fit' : 'h-full']">
        <div class="flex space-x-4">
          <button @click="toggleVideo"
            :class="[videoVisible ? 'bg-violet-200 text-indigo-700 font-semibold' : 'bg-gray-500 hover:bg-violet-200 hover:text-violet-500 text-white']"
            class="py-2 px-4 rounded-lg flex items-center">
            <svg v-if="videoVisible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            <svg v-if="!videoVisible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            Video
          </button>
          <button @click="showTranscript"
            :class="[transcriptVisible ? 'bg-violet-200 text-indigo-700 font-semibold' : 'bg-gray-500 hover:bg-violet-200 hover:text-violet-500 text-white']"
            class="py-2 px-4 rounded-lg flex items-center">
            <svg v-if="transcriptVisible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            <svg v-if="!transcriptVisible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            Transcript
          </button>
          <!-- <button @click="showComments"
            :class="[commentsVisible ? 'bg-violet-200 text-indigo-700 font-semibold' : 'bg-gray-500 hover:bg-violet-200 hover:text-violet-500 text-white']"
            class="py-2 px-4 rounded-lg flex items-center">
            <svg v-if="commentsVisible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            <svg v-if="!commentsVisible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            Comments
          </button> -->
        </div>
        <video ref="videoRef" v-if="videoVisible" :src="videoUrl" controls autoplay
          class="w-full mt-4 h-auto bg-black rounded-lg" @timeupdate="onTimeUpdate"></video>

        <div v-if="transcriptVisible" class="w-full mt-4 flex-grow flex flex-col overflow-hidden">
          <h2 class="text-xl font-semibold mb-2">Transcript</h2>
          <div class="transcript flex-grow bg-white p-4 rounded-lg overflow-y-auto">
            <div v-for="(entry, index) in processedTranscript" :key="index" :id="'transcript-entry-' + index"
              :class="isActive(entry) ? 'bg-green-200' : ''" class="mb-2 grid grid-cols-5 gap-4">
              <div class="col-span-1 font-bold">{{ !hideSpeaker(index) ? entry.speaker : "" }}</div>
              <div class="col-span-1 text-gray-500">{{ formatTime(entry.start_time) }}</div>
              <div class="col-span-3 cursor-pointer hover:rounded-md hover:text-indigo-600"
                @click="seekToTime(entry.start_time)">{{ entry.text }}</div>
            </div>
          </div>
        </div>
        <div v-if="commentsVisible" class="w-full mt-4 flex-grow flex flex-col overflow-hidden">
          <h2 class="text-xl font-semibold mb-2">Comments</h2>
          <div class="comments flex-grow bg-white p-4 rounded-lg overflow-y-auto">
            <!-- Add your comments section here -->
            <Comments />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Comments from '../components/Comments.vue';
import axios from 'axios';
import MeetingService from '@/services/meeting.service';
import Meeting from '@/models/meeting';

const route = useRoute();
const router = useRouter();

const videoUrl = ref<string | null>(null);
const videoPermissions = ref<string | null>(null);
const videoTranscript = ref<any[]>([]);
const transcriptVisible = ref<boolean>(false);
const commentsVisible = ref<boolean>(false);
const videoVisible = ref<boolean>(true);
const videoRef = ref<HTMLVideoElement | null>(null);
const currentTime = ref<number>(0);

const speakers = ref<string[]>([]);
const meetingDate = ref<string | null>(null);
const meetingName = ref<string | null>(null);

const isMeetingDetailsOpen = ref(true);
const isSummaryOpen = ref(true);
const isAttachmentOpen = ref(false);
const isEmailDraftOpen = ref(false);


const videoSummary = ref(null);
const selectedCategory = ref('titre');

const toggleAttachmentDetails = () => {
  isAttachmentOpen.value = !isAttachmentOpen.value;
};
const toggleMeetingDetails = () => {
  isMeetingDetailsOpen.value = !isMeetingDetailsOpen.value;
};

const toggleSummary = () => {
  isSummaryOpen.value = !isSummaryOpen.value;
};

const toggleEmailDraft = () => {
  isEmailDraftOpen.value = !isEmailDraftOpen.value;
};

const processKeyToCapitalize = (value: string) => {
  value = value.replaceAll('_', ' ');
  return value.substring(0, 1).toUpperCase() + value.substring(1, value.length);
};

const fetchVideoDetails = async (id: string) => {
  try {
    const meetingResponse = await MeetingService.getMeetingById(id);
    let meeting: Meeting | null;
    try {
      meeting = meetingResponse.data.meeting;
    }
    catch {
      console.error("Meeting could not be retrieved");
    }

    try {
      const videoData = await MeetingService.getVideo(id);
      videoUrl.value = videoData.video.link;
    } catch (error) {
      console.error("The thumbnail could not be retrieved.", error);
    }

    videoPermissions.value = meeting.permissions;
    meetingName.value = meeting.display_name;

    try {
      const summaryResponse = await MeetingService.getSummary(id);
      videoSummary.value = summaryResponse.data.summary.summary;
    }
    catch {
      console.error("The summary could not be retrieved.");
    }

    try {
      const transcriptResponse = await MeetingService.getTranscript(id);
      videoTranscript.value = transcriptResponse.data.transcript.segments;
    }
    catch {
      console.error("The transcript could not be retrieved.");
    }

    speakers.value = ['Speaker 1', 'Speaker 2', 'Speaker 3'];
    meetingDate.value = MeetingService.formatDateTime(meeting.created_at);
  } catch (error) {
    console.error('Error fetching meeting details:', error);
  }
};

const renderVideoSummary = (summary) => {
  console.log(summary);
  if(summary === '' || summary == null || summary.length === 0) return '/';

  if (typeof summary === 'string') {
    return summary;
  } else if (Array.isArray(summary)) {
    return summary.map(item => {
      if (typeof item === 'string') {
        return `<p>${item}</p>`;
      } else if (typeof item === 'object') {
        return `<div class="border-[1px] border-gray-300 bg-white p-2 rounded-lg mb-2">${renderObject(item)}</div>`;
      }
    }).join('');
  } else if (typeof summary === 'object') {
    return renderObject(summary);
  }
};

const renderObject = (obj) => {
  return Object.keys(obj).map(key => {
    return `<p class="mb-2"><strong>${processKeyToCapitalize(key)} :</strong> ${obj[key]}</p>`;
  }).join('');
};

const formatTime = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours().toString().padStart(2, '0');
  const mm = date.getUTCMinutes().toString().padStart(2, '0');
  const ss = date.getUTCSeconds().toString().padStart(2, '0');
  return `${hh}:${mm}:${ss}`;
};

const showTranscript = () => {
  transcriptVisible.value = !transcriptVisible.value;
  commentsVisible.value = false;
};

const showComments = () => {
  commentsVisible.value = !commentsVisible.value;
  transcriptVisible.value = false;
};

const toggleVideo = () => {
  if (videoVisible.value && videoRef.value) {
    currentTime.value = videoRef.value.currentTime;
  }
  videoVisible.value = !videoVisible.value;
  if (!videoVisible.value && videoRef.value) {
    videoRef.value.currentTime = currentTime.value;
  }
};

const seekToTime = (time: number) => {
  if (videoRef.value) {
    videoRef.value.currentTime = time - 2;
    videoRef.value.play();
  }
};

const isActive = (entry: any) => {
  return videoRef.value && videoRef.value.currentTime - 2 >= entry.start_time && videoRef.value.currentTime - 2 <= entry.end_time;
};

const hideSpeaker = (index: number) => {
  return index > 0 && videoTranscript.value[index].speaker === videoTranscript.value[index - 1].speaker;
};

const processedTranscript = computed(() => {
  return videoTranscript.value.map((entry, index) => {
    const hideSpeakerName = index > 0 && entry.speaker === videoTranscript.value[index - 1].speaker;
    return { ...entry, hideSpeakerName };
  });
});

const onTimeUpdate = () => {
  if (videoRef.value) {
    currentTime.value = videoRef.value.currentTime;
  }
};

let lastElementHighlightedId = null;

watch(currentTime, () => {
  videoTranscript.value.forEach((entry, index) => {
    const element = document.getElementById(`transcript-entry-${index}`);
    if (isActive(entry) && element) {
      if (lastElementHighlightedId) {
        document.getElementById(lastElementHighlightedId).style.background = "transparent";
      }
      lastElementHighlightedId = element.id;
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.style.background = "rgba(200, 200, 200, 0.5)";
    }
  });
});

onMounted(() => {
  fetchVideoDetails(route.params.id as string);
});
</script>

<style scoped>
.video-detail-page {
  /* Add any custom styles here */
}

.lock-icon {
  /* Add styles for the lock icon if needed */
}

.resize-x {
  resize: horizontal;
}

.transcript,
.comments {
  overflow-y: auto;
}

.transcript .active {
  background-color: #ffeb3b;
}

.transcript-entry {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0.5rem;
}

#summary-container {
  max-height: 300px;
  overflow-y: auto;
}

.custom-list li::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
}
</style>
