<template>
  <div class="meeting-container cursor-pointer border-2 border-transparent hover:border-2 hover:border-indigo-600 bg-white shadow rounded-lg overflow-hidden relative" @mouseover="showCheckbox = true"
    @mouseleave="showCheckbox = false" :class="(!isSelected) ? '' : 'border-2 border-indigo-600'">
    <input type="checkbox" class="checkbox absolute top-2 left-2 z-10 w-8 h-8 cursor-pointer accent-indigo-600" v-if="showCheckbox || isSelected"
      v-model="isSelected" @change="toggleSelection" />
    <MeetingThumbnail :meetingId="meetingId" :meetingUrl="meetingUrl" :permissions="permissions" @click="handleClick"/>
    <div class="p-4" :class="[isSelected ? 'bg-indigo-100':'']">
      <h3 class="text-lg font-semibold">{{ name }}</h3>
      <p class="text-sm" :class="[!isSelected ? 'text-gray-500':'text-gray-700']">{{ date }}</p>
      <p class="text-sm" :class="[!isSelected ? 'text-gray-500':'text-gray-700']">{{ duration }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, watchEffect, computed } from 'vue';
import MeetingThumbnail from './MeetingThumbnail.vue';

const props = defineProps({
  meetingId: {
    type: String,
    required: true
  },
  thumbnail: {
    type: String,
    required: true
  },
  meetingUrl: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  },
  duration: {
    type: String,
    required: true
  },
  permissions: {
    type: String,
    required: true
  }
});

const showCheckbox = ref(false);
const isSelected = ref(false);
const selectedMeetings = inject('selectedMeetings', ref(new Set()));

const emit = defineEmits(['play']);

const handleClick = () => {
  emit('play', { meetingId: props.meetingId, meetingUrl: props.meetingUrl });
};

watchEffect(() => {
  isSelected.value = selectedMeetings.value.has(props.meetingId);
});

const toggleSelection = () => {
  if (isSelected.value) {
    selectedMeetings.value.add(props.meetingId);
  } else {
    selectedMeetings.value.delete(props.meetingId);
  }
};
</script>

<style scoped>
.meeting-container {
  position: relative;
  box-sizing: border-box;
}

/* Optional: Additional styling for the larger checkbox */
input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
}

.checkbox {
  background-color: white;
  border: 2px solid gray;
  box-sizing: border-box;
  /* outline:2px solid #4f46e5; */
  border-radius: 0.25rem; /* Optional: Add some rounding to the checkbox */
}

.checkbox:hover {
  border:2px solid #4f46e5;
}

.checkbox:checked:focus {
  background-color: #4f46e5;
  border:2px solid white;
}


.checkbox:checked:hover {
  background-color: #4f46e5;
  border:2px solid white;
}

.checkbox:checked {
  background-color: #4f46e5;
  color:white;
}
</style>
