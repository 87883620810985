<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-row space-x-4">
      <button @click="prevMonth"
        class="-m-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
        <span class="sr-only">Previous month</span>
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
      </button>
      <div v-for="(month, index) in months" :key="index" class="calendar bg-white p-4">
        <div class="flex justify-between items-center mb-2">
          <span class="text-lg font-semibold text-gray-900">{{ monthNames[month.date.getMonth()] }} {{
            month.date.getFullYear() }}</span>
        </div>
        <div class="grid grid-cols-7 gap-1 text-xs leading-6 text-gray-500 mb-2">
          <div v-for="day in daysOfWeek" :key="day" class="font-bold text-center">{{ day }}</div>
        </div>
        <div class="isolate grid grid-cols-7 gap-px bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          <button v-for="day in month.days" :key="day.date" type="button" :class="dayClass(day)"
            @click="selectDay(day, month.date)">
            <time :datetime="day.date" class="mx-auto flex h-7 w-7 items-center justify-center">{{ day.date.getDate()
              }}</time>
          </button>
        </div>
      </div>
      <button @click="nextMonth"
        class="-m-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
        <span class="sr-only">Next month</span>
        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, onMounted } from 'vue';
import moment from 'moment-timezone';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';

const startDate = ref(null);
const endDate = ref(null);

const emit = defineEmits(['updateDate']);

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

const timezone = "Europe/Paris";
const currentDate = moment.tz(new Date(), timezone).toDate();
const selectedDates = ref({ start: startDate.value, end: endDate.value });

const createMonthData = (date) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const startDayOfWeek = start.getDay();

  const days = [];

  if (startDayOfWeek > 0) {
    const prevMonthEnd = new Date(start.getFullYear(), start.getMonth(), 0);
    for (let i = prevMonthEnd.getDate() - startDayOfWeek + 1; i <= prevMonthEnd.getDate(); i++) {
      days.push({
        date: new Date(start.getFullYear(), start.getMonth() - 1, i),
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
      });
    }
  }

  for (let i = 1; i <= end.getDate(); i++) {
    const dayDate = new Date(date.getFullYear(), date.getMonth(), i);
    days.push({
      date: dayDate,
      isCurrentMonth: true,
      isToday: currentDate.toDateString() === dayDate.toDateString(),
      isSelected: false,
    });
  }

  const endDayOfWeek = end.getDay();
  if (endDayOfWeek < 6) {
    for (let i = 1; i <= 6 - endDayOfWeek; i++) {
      days.push({
        date: new Date(end.getFullYear(), end.getMonth() + 1, i),
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
      });
    }
  }

  return {
    date,
    days,
  };
};

const months = ref([
  createMonthData(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
  createMonthData(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
]);

const selectDay = (day) => {
  const selectedDate = day.date;

  if (!selectedDates.value.start || (selectedDates.value.start && selectedDates.value.end)) {
    selectedDates.value.start = selectedDate;
    selectedDates.value.end = null;
  } else if (selectedDates.value.start && !selectedDates.value.end) {
    if (selectedDate < selectedDates.value.start) {
      selectedDates.value.end = selectedDates.value.start;
      selectedDates.value.start = selectedDate;
    } else {
      selectedDates.value.end = selectedDate;
    }
  }

  refreshDaySelected();

  emit('updateDate', { startDate: selectedDates.value.start, endDate: selectedDates.value.end });
};

const refreshDaySelected = () => {
  months.value.forEach(month => {
    month.days.forEach(day => {
      day.isSelected = false;
      const date = day.date;
      if (selectedDates.value.start && date.getTime() === selectedDates.value.start.getTime()) {
        day.isSelected = true;
      } else if (selectedDates.value.end && date.getTime() === selectedDates.value.end.getTime()) {
        day.isSelected = true;
      } else if (selectedDates.value.start && selectedDates.value.end && date > selectedDates.value.start && date < selectedDates.value.end) {
        day.isSelected = true;
      }
    });
  });
}

const dayClass = (day) => {
  let classes = "";
  if (!day.isCurrentMonth) {
    classes += " bg-gray-100 text-gray-400";
  } else if (day.isSelected) {
    classes += " bg-indigo-600 text-white";
  } else if (day.isToday) {
    classes += " bg-indigo-200 text-indigo-600";
  } else {
    classes += " bg-white text-gray-900 hover:bg-gray-100";
  }
  return classes;
};

const prevMonth = () => {
  const firstMonthDate = new Date(months.value[0].date);
  firstMonthDate.setMonth(firstMonthDate.getMonth() - 1);
  months.value[0] = createMonthData(firstMonthDate);

  const secondMonthDate = new Date(firstMonthDate);
  secondMonthDate.setMonth(secondMonthDate.getMonth() + 1);
  months.value[1] = createMonthData(secondMonthDate);

  months.value = [...months.value];
  refreshDaySelected();
};

const nextMonth = () => {
  const secondMonthDate = new Date(months.value[1].date);
  secondMonthDate.setMonth(secondMonthDate.getMonth() + 1);
  months.value[1] = createMonthData(secondMonthDate);

  const firstMonthDate = new Date(secondMonthDate);
  firstMonthDate.setMonth(firstMonthDate.getMonth() - 1);
  months.value[0] = createMonthData(firstMonthDate);

  months.value = [...months.value];
  refreshDaySelected();
};

onMounted(() => {
  refreshDaySelected();
});
</script>
