// src/utils/auth.ts
import ApiService from '../services/api.service';

export async function isTokenValid(token: string): Promise<boolean> {
  if (!token) return false;
  try {
    // Assuming there's an endpoint to validate the token
    await ApiService.instance.get('/users/me');
    return true;
  } catch (error) {
    return false;
  }
}
