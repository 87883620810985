import { createI18n, LocaleMessages } from 'vue-i18n'
import enUS from './en-US/common.json'
import fr from './fr/common.json'

type MessageSchema = typeof enUS


const i18n = createI18n<[MessageSchema], 'en-US' | 'fr'>({
  locale: 'en-US', // set default locale
  messages: {
    'en-US': enUS,
    'fr': fr
  }
})

export default i18n
