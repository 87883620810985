import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from '@/store'; // import the store
import { isTokenValid } from '@/utils/auth';
import MeetingsListView from "@/views/MeetingsListView.vue";
import MeetingView from "@/views/MeetingView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import TeamView from "@/views/TeamView.vue";
import CalendarView from "@/views/CalendarView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: MeetingsListView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
  },
  {
    path: "/logout",
    name: "Logout",
    component: LoginView,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: CalendarView,
  },
  {
    path: "/team",
    name: "Team",
    component: TeamView,
  },
  {
    path: "/meetings",
    name: "Meetings",
    component: MeetingsListView,
  },
  {
    path: "/meeting/:id",
    name: "MeetingDetail",
    component: MeetingView,
    props: true
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = store.state.token;

  if (token) {
    const valid = await isTokenValid(token);
    if (!valid) {
      store.commit('SET_TOKEN', ''); // Clear token in Vuex store
      store.commit('SET_USER', null); // Clear user data
      next({ name: 'Login' });
    } else {
      if (to.name === 'Login') {
        next({ name: 'home', query: { action: 'disconnect' } });
      } else {
        next();
      }
    }
  } else {
    if (to.name !== 'Login' && to.name !== 'Register') {
      next({ name: 'Login' });
    } else {
      next();
    }
  }
});

export default router;
