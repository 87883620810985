<template>
  <div class="meetings-page h-screen">
    <div class="filters-container z-10 mb-4 mt-2 bg-white py-2 rounded-md shadow">
      <div class="filters flex gap-2 items-center w-full px-2">

        <ButtonGray text="All meetings" :active="currentFilter === 'all'" @click="setFilter('all')"/>
        <ButtonGray text="Private" :active="currentFilter === 'private'" @click="setFilter('private')"/>
        <ButtonGray text="Shared with me" :active="currentFilter === 'shared'" @click="setFilter('shared')"/>
        <ButtonGray text="Workspaces" :active="currentFilter === 'workspace'" @click="setFilter('workspace')"/>
        <ButtonGray text="Groups" :active="currentFilter === 'group'" @click="setFilter('group')"/>

        <!-- Search Form -->
        <form class="relative flex-grow" @submit.prevent>
          <label for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="search" id="default-search" v-model="searchQuery" placeholder="Search (Ctrl+K)"
              class="block w-full p-2.5 pr-[50px] pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
              @keydown.ctrl.k.prevent="focusSearch" required />
            <button type="button"
              class="text-gray absolute right-2.5 bottom-1.5  hover:bg-gray-200 focus:outline-none font-medium rounded-lg text-sm px-1 py-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
              </svg>
            </button>
          </div>
        </form>

        <!-- Datepicker Button -->
        <button :class="['px-4 py-2 rounded flex items-center  bg-white hover:bg-gray-200 border border-gray-300 mr-2']"
          @click="toggleDatepicker">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
          </svg>
          <div v-if="startDate && endDate" class="ml-2"> {{ formatDisplayDateRange }}</div>
        </button>
      </div>

      <!-- Datepicker Component -->
      <div v-if="showDatepicker" id="ranges-with-time-tab-preview-datepicker"
        class="absolute z-50 right-0 top-20  md:w-[40.4rem] flex flex-col bg-white border shadow-lg rounded-xl overflow-hidden mb-4">
        <Datepicker @updateDate="updateSelectedDates"></Datepicker>
      </div>
    </div>

    <div class="meeting-grid-container mt-2 overflow-y-auto">
      <div v-if="filteredMeetings.length == 0" class="w-full h-full relative flex items-center justify-center">
        <div
          class="absolute top-0 left-0 right-0 bottom-5 w-96 h-64  flex items-center border-dashed border-2 border-gray-300 rounded-md justify-center text-center p-4 m-auto">
          <span v-if="meetings.length > 0" class="text-gray-500">No meeting found with these filters</span>
          <span v-else class="text-gray-500">No meeting found</span>
        </div>
      </div>

      <div v-if="filteredMeetings.length > 0"
        class="meeting-grid w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <MeetingContainer v-for="meeting in filteredMeetings" :key="meeting.id" :meetingId="meeting.id"
          :thumbnail="meeting.thumbnail" :meetingUrl="meeting.meetingUrl" :name="meeting.name"
          :date="MeetingService.formatDateTime(meeting.created_at)" :duration="getDurationString(meeting.duration)"
          :permissions="meeting.permissions" @play="playMeeting" />
      </div>
    </div>

    <div v-if="selectedMeetings.size > 0"
      class="selected-meetings-bubble flex items-center absolute bottom-4 w-fit left-0 right-0 m-auto bg-indigo-600 text-white px-4 py-2 rounded-full shadow-lg">
      <span class="ml-2 mr-1"><span class="font-bold mr-1">{{ selectedMeetings.size }}</span> meeting{{
        selectedMeetings.size > 1 ? 's' : '' }} selected</span>
      <button @click="deleteSelection" class="ml-2 hover:opacity-75">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
      </button>
      <button @click="archiveSelection" class="mx-2 mr-0 hover:opacity-75">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
        </svg>
      </button>
      <button @click="clearSelection" class="ml-1 hover:opacity-75">
        <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
      </button>
    </div>

    <DeleteModal v-if="isDeleteModalVisible" @confirm="confirmDelete" @cancel="displayDeleteModal"
      @close="displayDeleteModal" />
    <!-- <WarningAlert class="absolute top-5 left-0 right-0 w-fit m-auto shadow-lg z-[1001]" title="Selection archived" description="The meetings were archived"/> -->

    <div v-if="showSuccessAlert"
      class="alert alert-success absolute top-5 left-0 right-0 w-fit m-auto shadow-lg z-[1001]">
      <p>{{ successMessage }}</p>
      <button @click="showSuccessAlert = false" class="ml-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div v-if="showErrorAlert" class="alert alert-error absolute top-5 left-0 right-0 w-fit m-auto shadow-lg z-[1001]">
      <p>{{ errorMessage }}</p>
      <button @click="showErrorAlert = false" class="ml-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, provide, watch, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import moment from 'moment-timezone';

import ButtonGray from '@/components/design/ButtonGray.vue';

import MeetingContainer from '@/components/MeetingContainer.vue';
import Datepicker from '@/components/Datepicker.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import WarningAlert from '@/components/alerts/WarningAlert.vue';

// Services
import MeetingService from '@/services/meeting.service';
import { Meeting } from '@/models/meeting';

const store = useStore();

const showSuccessAlert = ref(false);
const showErrorAlert = ref(false);
const successMessage = ref('');
const errorMessage = ref('');

const getDurationString = (duration: number): string => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  return `${hours > 0 ? `${hours}h` : ''} ${minutes}m ${seconds}s`;
};

const permissionsList = ['public', 'private', 'team', 'organization'];

const meetings = computed(() => {
  const stateMeetings = store.state.meetings || [];
  if (stateMeetings.length === 0) {
    console.warn("No meeting retrieved for now");
  }
  return stateMeetings;
});

const formatDisplayDateRange = computed(() => {
  const formattedStartDate = startDate.value ? moment(startDate.value).format('YYYY/MM/DD') : null;
  const formattedEndDate = endDate.value ? moment(endDate.value).format('YYYY/MM/DD') : null;
  return `${formattedStartDate} - ${formattedEndDate}`;
});

const filteredMeetings = ref([...meetings.value]);
const searchQuery = ref('');
const currentFilter = ref('all');
const selectedMeetings = ref(new Set<string>());
const router = useRouter();
const searchInputRef = ref<HTMLInputElement | null>(null);

const selectedMonth = ref(new Date().getMonth());
const selectedYear = ref(new Date().getFullYear());

const startDate = ref<Date | null>(null);
const endDate = ref<Date | null>(null);

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const years = Array.from({ length: 10 }, (v, k) => k + 2023);

const daysInMonth = computed(() => {
  const date = new Date(selectedYear.value, selectedMonth.value + 1, 0);
  return Array.from({ length: date.getDate() }, (v, k) => k + 1);
});

provide('selectedMeetings', selectedMeetings);

const filterMeetings = () => {
  filteredMeetings.value = meetings.value.filter((meeting: Meeting) => {
    const matchesSearchQuery = meeting.name.toLowerCase().includes(searchQuery.value.toLowerCase());
    
    let startDateValue = startDate.value;

    if(startDateValue) startDateValue.setHours(0, 0, 0, 999);

    let endDateValue = endDate.value;
    if (startDate.value && !endDate.value) {
      endDateValue = new Date(startDateValue);
      endDateValue.setHours(23, 59, 59, 999);
    }
    else if(endDate.value) {
      endDateValue.setHours(23, 59, 59, 999);
    }

    const matchesDateRange = !startDateValue || !endDateValue || 
      (new Date(meeting.created_at) >= new Date(startDateValue) && new Date(meeting.created_at) <= endDateValue);

    return matchesSearchQuery && matchesDateRange;
  });
};


const setFilter = (filter: string) => {
  currentFilter.value = filter;
  filterMeetings();
};

const playMeeting = (meetingDetails: any) => {
  router.push({ name: 'MeetingDetail', params: { id: meetingDetails.meetingId } });
};

const clearSelection = () => {
  selectedMeetings.value.clear();
};

const deleteSelection = () => {
  displayDeleteModal(true);
};

const focusSearch = () => {
  searchInputRef.value?.focus();
};

const updateSelectedDates = (selectedDates) => {
  console.log('Selected Dates:', selectedDates);
  startDate.value = selectedDates.startDate;
  endDate.value = selectedDates.endDate;
  console.log('Start Date:', startDate.value);
  console.log('End Date:', endDate.value);
  filterMeetings();
}

const clearFilter = () => {
  startDate.value = null;
  endDate.value = null;
  filterMeetings();
};

const applyFilter = () => {
  filterMeetings();
  toggleDatepicker();
};

const toggleDatepicker = () => {
  showDatepicker.value = !showDatepicker.value;
};

const showDatepicker = ref(false);

watch([searchQuery, currentFilter, startDate, endDate], filterMeetings);

watch(() => store.state.meetings, (newMeetings) => {
  console.log(newMeetings);
  filteredMeetings.value = newMeetings;
  filterMeetings();
});

onMounted(() => {
  if (store.state.token) {
    store.dispatch('fetchUserData');

    if (store.state.currentWorkspace) {
      store.dispatch('fetchMeetings', { workspaceId: store.state.currentWorkspace.id });
    } else {
      console.error("No current workspace set!");
    }
  }
});

const isDeleteModalVisible = ref(false);

const displayDeleteModal = (value: boolean) => {
  isDeleteModalVisible.value = value;
};

const confirmDelete = async () => {
  try {
    for (const selectedMeetingId of selectedMeetings.value) {
      await MeetingService.deleteMeetingId(selectedMeetingId);
    }
    store.dispatch('fetchMeetings', { workspaceId: store.state.currentWorkspace.id });
    selectedMeetings.value.clear();
    successMessage.value = 'Selected meetings deleted successfully';
    showSuccessAlert.value = true;
  } catch (error) {
    errorMessage.value = 'Error deleting meetings';
    showErrorAlert.value = true;
    console.error('Error deleting meetings:', error);
  } finally {
    isDeleteModalVisible.value = false;
  }
};

const archiveSelection = async () => {
  try {
    for (const selectedMeetingId of selectedMeetings.value) {
      await MeetingService.archiveMeetingId(selectedMeetingId);
    }
    store.dispatch('fetchMeetings', { workspaceId: store.state.currentWorkspace.id });
    selectedMeetings.value.clear();
    successMessage.value = 'Selected meetings archived successfully';
    showSuccessAlert.value = true;
  } catch (error) {
    errorMessage.value = 'Error archiving meetings';
    showErrorAlert.value = true;
    console.error('Error archiving meetings: ', error);
  }
};
</script>

<style scoped>
.meetings-page {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meeting-grid-container {
  max-height: calc(100vh - 90px);
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.meeting-grid {
  padding: 0 1rem 2rem 0;
}

.alert {
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
}
</style>