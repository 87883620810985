<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div class="bg-white p-6 rounded-md shadow-lg">
      <h2 class="text-lg font-bold mb-4">Confirm Deletion</h2>
      <p class="mb-4">Please type <strong>delete</strong> to confirm:</p>
      <input v-model="input" type="text" class="w-full p-2 border border-gray-300 rounded mb-4" />
      <div class="flex justify-end">
        <button @click="confirm" :disabled="input !== 'delete'"
          class="bg-red-600 text-white px-4 py-2 rounded mr-2 hover:bg-red-700 disabled:opacity-50">Confirm</button>
        <button @click="cancel" class="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from 'vue';

const input = ref('');

const emit = defineEmits(['confirm', 'cancel', 'close']);

const confirm = () => {
  emit('confirm');
  closeModal();
};

const cancel = () => {
  emit('cancel', false);
  closeModal();
};

const closeModal = () => {
  emit('close', false);
};

</script>

<style scoped>
/* Add any necessary styling here */
</style>
