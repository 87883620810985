import { createStore } from "vuex";
import Cookies from "js-cookie";

// Services
import UserService from "../services/user.service";
import MeetingService from "../services/meeting.service";
import WorkspaceService from "../services/workspace.service";

// Models
import { User } from "../models/user";
import { Meeting } from "../models/meeting";
import { Workspace } from "../models/workspace";

export default createStore({
  state: {
    token: Cookies.get("token") || "",
    user: null as User | null,
    meetings: [] as Meeting[],
    workspaces: [] as Workspace[],
    currentWorkspace: null as Workspace | null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      return new Promise<void>((resolve) => {
        state.token = token;
        console.log("etape 1");
        if (token) {
          Cookies.set("token", token, {
            secure: false,
            sameSite: "Strict",
            expires: 7,
          });
        } else {
          Cookies.remove("token");
        }
        resolve();
      });
    },
    SET_USER(state, user: User) {
      state.user = user;
    },
    SET_MEETINGS(state, meetings: Meeting[]) {
      state.meetings = meetings;
    },
    SET_WORKSPACES(state, workspaces: Workspace[]) {
      state.workspaces = workspaces;
    },
    SET_CURRENT_WORKSPACE(state, workspace: Workspace) {
      state.currentWorkspace = workspace;
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await UserService.login(username, password);
        const token = response.data.access_token;
        console.log(token);
        await commit("SET_TOKEN", token);
        console.log("etape 2");
        await this.dispatch("fetchUserData");
      } catch (error) {
        console.error("Error logging in:", error);
        throw error;
      }
    },
    async register({ commit }, { username, email, password }) {
      try {
        await UserService.register(username, email, password);
        await this.dispatch("login", { username, password });
      } catch (error) {
        console.error("Error registering:", error);
      }
    },
    async fetchUserData({ commit }) {
      try {
        const response = await UserService.getUserData();
        commit("SET_USER", response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async fetchMeetings({ commit }, { workspaceId }) {
      try {
        const response = await MeetingService.getMeetings(workspaceId);
        console.log(response.data);

        // Extracting groups_meetings and shared_meetings from response.data
        const groupsMeetings = response.data.groups_meetings;
        const sharedMeetings = response.data.shared_meetings;

        // Flattening all meetings from groupsMeetings
        const groupMeetings = groupsMeetings.flatMap((group) => group.meetings);

        // Merging the group meetings and shared meetings into a single array
        const allMeetings = [...groupMeetings, ...sharedMeetings];

        // Filtering out duplicate meetings based on unique id
        const uniqueMeetingsMap = new Map();
        allMeetings.forEach((meeting) =>
          uniqueMeetingsMap.set(meeting.id, meeting)
        );

        // Converting the Map back to an array to get the unique meetings
        const uniqueMeetings = Array.from(uniqueMeetingsMap.values());

        console.log(uniqueMeetings);

        commit("SET_MEETINGS", uniqueMeetings);
      } catch (error) {
        console.error("Error fetching meetings:", error);
      }
    },
    async fetchWorkspaces({ commit }) {
      try {
        console.log("Start fetching workspaces");
        const response = await WorkspaceService.getWorkspaces();
        await commit("SET_WORKSPACES", response.data.workspaces);
        console.log(this.state.currentWorkspace);
        if (!this.state.currentWorkspace) {
          await commit("SET_CURRENT_WORKSPACE", response.data.workspaces[0]);
        }
        console.log(this.state.currentWorkspace);
      } catch (error) {
        console.error("Error fetching workspaces:", error);
      }
    },
  },
  getters: {
    getWorkspaceById: (state) => (id: string) => {
      return state.workspaces.find((workspace) => workspace.id === id) || null;
    },
    getMeetingById: (state) => (id: string) => {
      return state.meetings.find((meeting) => meeting.id === id) || null;
    },
  },
  modules: {},
});
