import ApiService from "@/services/api.service";
import { Meeting } from "@/models/meeting";
import { Group } from "@/models/group";

export interface GroupsMeetingsResponse {
  groups_meetings: Group[];
  shared_meetings: Meeting[];
}
export interface MeetingResponse {
  meeting: Meeting[];
}

class MeetingService {
  getMeetings(workspaceId: string) {
    return ApiService.instance.get<GroupsMeetingsResponse>(
      `/workspace/${workspaceId}/meetings`
    );
  }

  getMeetingById(meetingId: string) {
    const meeting = ApiService.instance.get<MeetingResponse>(
      `/meeting/${meetingId}`
    );
    console.log(meeting);
    return meeting;
  }

  deleteMeetingId(meetingId: string) {
    const meeting = ApiService.instance.delete<MeetingResponse>(
      `/delete/meeting/${meetingId}`
    );
    console.log(meeting);
    return meeting;
  }

  archiveMeetingId(meetingId: string) {
    const meeting = ApiService.instance.put<MeetingResponse>(
      `/archive/meeting/${meetingId}`, {'is_archived': true}
    );
    console.log(meeting);
    return meeting;
  }

  getMeetingByGroupId(groupId: string) {
    const meetings = ApiService.instance.get<MeetingResponse>(
      `/group/${groupId}/meetings`
    );
    console.log(meetings);
    return meetings;
  }

  getSummary(meetingId: string) {
    return ApiService.instance.get(`/meeting/${meetingId}/resume`);
  }

  getTranscript(meetingId: string) {
    return ApiService.instance.get(`/meeting/${meetingId}/transcript`);
  }

  async getVideo(meetingId: string) {
    try {
      const response = await ApiService.instance.get(`/meeting/${meetingId}/video`);
      return response.data;
    } catch (error: any) {
      throw new Error('Error fetching video: ' + error.response.data.message);
    }
  }

  async getThumbnail(meetingId: string) {
    try {
      const response = await ApiService.instance.get(`/meeting/${meetingId}/thumbnail`);
      return response.data;
    } catch (error: any) {
      throw new Error('Error fetching thumbnail: ' + error.response.data.message);
    }
  }

  // Utils
  formatDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);

    const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed

    return `${month}/${day}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
  };
}

export default new MeetingService();
